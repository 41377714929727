import { Injectable, ElementRef } from '@angular/core';
import { ConexionService } from './conexion.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { Emp } from '@models/emps.model';
import { Fcv, FcvDet } from '@models/fcvs.model';
import { NumerosFormatoPipe } from '@pipes/numerosFormato.pipe';
import { CerosPipe } from '@pipes/ceros.pipe';
import { getMonedaByMonId, numeroALetras } from 'src/assets/js/numerosALetras'
import { take } from 'rxjs/operators';
import { Fcp } from '@models/fcps.model';
import moment from 'moment'; import { GlobalService } from './global.service';
import { Impuesto } from '../models/arts.model';
import * as jsPDF from 'jspdf';
import { CartaPorteV2, Remolque } from '@root/models/cartaPorteDataV2.model';
import { ImpuestoCode2Text } from '@root/utils/texts.utils';


@Injectable({
  providedIn: 'root'
})
export class PdfFactura {
  numerosFormato = new NumerosFormatoPipe
  ceros = new CerosPipe
  selloDigital = `TESTING - - - -  l1JACxVsFpTESTINGMonsrzTESTINGwZqrBTowJTESTINGEA9a0TESTINGC2mMatrGBwjAA+YT7JRrGyR95YHLkSOcwgisK42UnktiU4cgWYwTWf9/URExxP5RsdJPWSpAoafQoNo7UtmGrcL2YJCQhuw5+z265O9lXFOWzJeEjPg=`;
  selloDigitalSAT = `TESTING  - - - -   iAYPzX9uZncTnGvq98h1octgONCNKJ90+IUP39OYhQ4nT+/DVVwLqY6xNraAY7WG2Y4vLksBDD7NpHs076MZxC49PHCXJsfidmJnjybI3hRrGpA22dPNVvISMks404KsIgqCX56aFd0Ljr+ENnr2b4+q7z2GgqsgMo1+Q1JzGHu6Qf0qctoXeuIJPNa6rQywokFwWU+MPuI01kwBp7ePTSdRlWJ/9tw0sBWaU/PalmvXgfHhjrIsFssFSXwcimDzZw9UAo2/V6v0H4NR3G70J2ACcJUiA7UbGczsPCMWKoVLICHMRgz0HZCkNGYj2+y36BzuN2U9T0DnpQCsyeTQA==`;
  cadenaOriginal = `TESTING  - - - - ||1.1|FBC362EA-DED7-405C-BA85-5F0004044DC1|2019-02-27T10:51:44|l1JACxVsFp7UVUvJMonsrzTESTINGwZqrBTowJTESTINGEA9a0TESTINGC2mMatrGBwjAA+YT7JRrGyR95YHLkSOcwgisK42UnktiU4cgWYwTWf9/URExxP5RsdJPWSpAoafQoNo7UtmGrcL2YJCQhuw5+z265O9lXFOWzJeEjPg=|00001000000403442064||`;
  enters = 0

  imagenesCargadas: number = 0
  imagenesPendientes: number = 0
  emp: Emp;
  fcv: Fcv = new Fcv();
  pdfDoc: jsPDF = null
  tamLinea = 5;
  linea = 5;

  // COLORS
  gris = [65, 65, 67];
  blanco = [255, 255, 255];
  blanco_gris = [235, 235, 235];
  separador = [240, 240, 240];

  Y = 0;
  X = 0;
  constructor(
    private con: ConexionService,
    private store: Store<AppState>,
    private g: GlobalService,
  ) { }

  /**
   * La funcion genera un PDF de Venta  con los datos del Venta desde el store, o bien usando datos hardcodeados de prueba.
   *
   * @param contenedor : Elemento canvas tipo elementRF (usando notacion Viewchild # ) >>> <iframe fxFlex="100" type="application/pdf" #miVisor class="miVisor"></iframe>
   * @param demo : Si se va a usar (default true) o no (false) los datos de prueba y no un comprobante real del store
   * @param blobQR : QR en formato blob string
   * @param guardar : Si se va a devolver el bloburi (default) o se va a guardar
   */
  generarPDF(contenedor?: any, demo: boolean = true, blobQR: string = '', salida: 'GUARDAR' | 'VISTA' | 'BLOB' = 'VISTA'): Promise<any> {


    return new Promise((ok, er) => {

      new Promise((res, rej) => {

        this.store.pipe(take(1)).subscribe(store => {

          if (!demo) { this.fcv = store.fcv; }
          this.emp = this.fcv?.idEst !== 10 ? this.fcv?.emp : store.emp;
          //PRE-CARGA DE IMAGENES
          let imagenes = {}
          let cantidadImagenes = 3
          this.con.sGetImg(this.emp.logo, 'logos').then(logoUrl => {
            // logo
            let codigoQR = this.imagenObjetoHTML(blobQR);
            codigoQR.onload = () => {
              imagenes = { ...imagenes, codigoQR };
              if (Object.keys(imagenes).length === cantidadImagenes) { res(imagenes); }
            }
            // logo
            let logo = this.imagenObjetoHTML(logoUrl);
            logo.onload = () => {
              imagenes = { ...imagenes, logo };
              if (Object.keys(imagenes).length === cantidadImagenes) { res(imagenes); }
            }
            // formato
            let formatoUrl = 'assets/formatosPDF/f1-gris-rojo.jpg';
            formatoUrl = `assets/formatosPDF/${Number(this.emp.formatoPDF) || 1}f.jpg`;
            let formato = this.imagenObjetoHTML(formatoUrl);
            formato.onload = () => {
              imagenes = { ...imagenes, formato };
              if (Object.keys(imagenes).length === cantidadImagenes) { res(imagenes); }
            }
          })
        });

      }).then((imagenes: any) => {
        // SEDECLARAN IMAGENES QUE SE VAN A USAR
        let formato = imagenes.formato;
        let logo = imagenes.logo;
        let codigoQR = imagenes.codigoQR;
        // CONFIGURACION DE PAGINA ------------------------------------------------------.

        let configuracioPagina = {
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
          compressPdf: true,
        }
        this.pdfDoc = new jsPDF(configuracioPagina)
        let hojaAncho = 210;
        let hojaAlto = 297;
        // ------------------------------------------------------------------------------.
        // FORMATO ----------------------------------------------------------------------.

        let ratio = (<any>formato).width / (<any>formato).height
        let ancho = 210;
        let alto = 89;
        this.pdfDoc.addImage(formato, '', 0, 0, ancho, alto)
        // ------------------------------------------------------------------------------.
        // LOGO -------------------------------------------------------------------------.
        // let logo = this.imagenObjetoHTML(<string>imgUrl)
        ancho = (<any>logo).width;
        alto = (<any>logo).height;
        ratio = (<any>logo).width / (<any>logo).height

        if (alto >= ancho) {
          alto = 37;
          ancho = alto * ratio;
        } else {
          ancho = 39;
          alto = ancho / ratio;
        }
        this.pdfDoc.addImage(logo, '', 2, 4, ancho, alto);

        // NOTA: Las imagenes tardan en cargar, entonces al final 	se espera que carguen todas
        // ------------------------------------------------------------------------------.

        // TIPO DE COMPROBANTE ----------------------------------------------------------.
        this.X = 204
        this.Y = 9
        this.pdfDoc.setFontSize(14); this.tamLinea = 5;
        this.pdfDoc.setTextColor.apply(null, this.blanco_gris);
        let cmp = <string>this.g.mdl(this.fcv.idMdl).nombreAbr;
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text(cmp.toUpperCase(), this.X, this.Y, { align: 'right' });
        // ------------------------------------------------------------------------------.

        // DATOS CABECERA ---------------------------------------------------------------.
        this.pdfDoc.setFontSize(10); this.tamLinea = 5;
        this.pdfDoc.setTextColor.apply(null, this.blanco_gris);
        this.pdfDoc.setFont('Helvetica', '');
        this.X = 180
        this.Y = 17
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Folio:', this.X, this.Y, { align: 'right' });
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${this.ceros.transform(this.fcv.numero, 10) || '0000000001'}`, this.X + 2, this.Y);
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Emisión:', this.X, this.sigLinea(), { align: 'right' });
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${moment(this.fcv.fecEmi).format('DD-MM-YYYY') || '01-01-2000'}`, this.X + 2, this.Y);
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Vencimiento:', this.X, this.sigLinea(), { align: 'right' });
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${moment(this.fcv.fecVto).format('DD-MM-YYYY') || '01-01-2000'}`, this.X + 2, this.Y);

        if (this.fcv.uuid && this.fcv.uuid.length > 5) {
          this.pdfDoc.setFontSize(9); this.tamLinea = 4;
          this.X = 148
          this.Y = 35
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('UUID', this.X, this.Y)
          this.pdfDoc.setFontSize(8);
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${this.fcv.uuid || 'FBC362EA-DED7-405C-TEST-TEST0404TEST'}`, this.X, this.sigLinea())
        }
        // ------------------------------------------------------------------------------.
        // DATOS EMISOR -----------------------------------------------------------------.
        this.pdfDoc.setFontSize(9); this.tamLinea = 4;
        this.pdfDoc.setTextColor.apply(null, (this.emp.formatoPDF == 7 ? this.gris : this.blanco_gris));
        this.pdfDoc.setFont('Helvetica', '');
        this.X = 42
        this.Y = 14
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text(this.emp.emp, this.X, this.Y)
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Razón Social:', this.X, this.sigLinea());
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(this.emp.rso, this.X + 22, this.Y);
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Domicilio:', this.X, this.sigLinea());
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${this.emp.fisDom} No. ${this.emp.fisDomNro}${this.emp.fisDomInt ? ' Int. ' + this.emp.fisDomInt : ''}`, this.X + 18, this.Y);
        this.pdfDoc.text(`${this.emp.fisDomCol}, ${this.emp.fisDomMun}, ${this.emp.fisDomRegion.region}, ${this.emp.fisDomPais.name}`, this.X, this.sigLinea());
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('RFC:', this.X, this.sigLinea());
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(this.emp.rfc, this.X + 8, this.Y);
        this.pdfDoc.text(this.emp.regFis.regFis, this.X, this.sigLinea());
        // ------------------------------------------------------------------------------.
        // DATOS RECEPTOR ---------------------------------------------------------------.
        this.pdfDoc.setFontSize(9); this.tamLinea = 4;
        this.pdfDoc.setTextColor.apply(null, this.gris);
        this.pdfDoc.setFont('Helvetica', '');
        this.X = 5
        this.Y = 52
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text(`${!demo ? this.fcv.fcp.fcp : 'Empresa De Prueba'}`, this.X, this.Y)

        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Razón Social:', this.X, this.sigLinea());
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${!demo ? this.fcv.fcp.rso : 'Empresa Prueba SRL'}`, this.X + 22, this.Y);
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Domicilio:', this.X, this.sigLinea());
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${!demo ? Fcp.getDomicilioFiscal(this.fcv.fcp) : 'Calle wallaby 42'}`, this.X + 18, this.Y);
        this.pdfDoc.text(`${!demo ? Fcp.getLocalidadFiscal(this.fcv.fcp) : 'Sidney, Australia'}`, this.X, this.sigLinea());
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('RFC:', this.X, this.sigLinea());
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${!demo ? this.fcv.fcp.rfc : 'ASDF880623MF1'}`, this.X + 8, this.Y);
        this.pdfDoc.text(`${!demo ? this.fcv.fcp?.regFis?.regFis || '' : 'Régimen de Incorporación Fiscal'}`, this.X, this.sigLinea());
        if (this.fcv.ordenCompra) {
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Órden de Compra:', this.X, this.sigLinea());
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(this.fcv.ordenCompra, this.X + 29, this.Y);
        }
        if (this.fcv.numeroProveedor) {
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Número Proveedor:', this.X, this.sigLinea());
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(this.fcv.numeroProveedor, this.X + 30, this.Y);
        }

        // ------------------------------------------------------------------------------.
        // DATOS CABECERA ---------------------------------------------------------------.
        this.pdfDoc.setFontSize(9); this.tamLinea = 4;
        this.pdfDoc.setTextColor.apply(null, this.gris);
        this.pdfDoc.setFont('Helvetica', '');

        this.X = 146
        this.Y = 55
        if (this.fcv.uuid && this.fcv.uuid.length > 5) {
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Fecha Certificacion', this.X, this.Y, { align: 'right' });
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(moment(this.fcv.fecCer).format('YYYY-MM-DDTHH:mm:ss'), this.X + 2, this.Y);
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('No. Serie Certificado SAT', this.X, this.sigLinea(), { align: 'right' });
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${this.fcv.nroSerSat || 'TEST1000000403442064'}`, this.X + 2, this.Y);
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('No. Certificado', this.X, this.sigLinea(), { align: 'right' });
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${this.fcv.nroSerCsdEmi || 'TEST0000000414320912'}`, this.X + 2, this.Y);
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Método de Pago', this.X, this.sigLinea(), { align: 'right' });
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${this.getMetodoDePago() || 'PPD - Pago en parcialidades o diferido-TEST'}`, this.X + 2, this.Y);
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Forma de Pago', this.X, this.sigLinea(), { align: 'right' });
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${this.getFormaDePago() || '01 - Efectivo-TEST'}`, this.X + 2, this.Y);
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Uso CFDI', this.X, this.sigLinea(), { align: 'right' });
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${this.getUsoCfdi() || 'P01 - Por definir'}`, this.X + 2, this.Y);
          if (this.fcv.fcp?.idExtranjeroFiscal && this.fcv.fcp?.rfc === 'XEXX010101000') {
            this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Reg. Fiscal Extranjero', this.X, this.sigLinea(), { align: 'right' });
            this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${this.fcv.fcp?.idExtranjeroFiscal || '-'}`, this.X + 2, this.Y);
          }
        }
        // ------------------------------------------------------------------------------.

        // ------------------------------------------------------------------------------.
        // ----------------------------------- ARTICULOS --------------------------------.
        // ------------------------------------------------------------------------------.


        // TITULOS
        this.pdfDoc.setFontSize(9); this.tamLinea = 4;
        this.pdfDoc.setTextColor.apply(null, this.gris);
        this.pdfDoc.setFont('Helvetica', '');
        this.Y = 95
        if (demo) {
          for (let i = 0; i < 4; i++) {
            this.X = 8
            this.pdfDoc.text('PROPR - Producto de Pruebas', this.X, this.Y); //CODIGO - PRODUCTO
            this.pdfDoc.text('Codigo SAT: 811125000', this.X, this.Y + 5); // CODIGO SAT
            this.X = 70
            this.pdfDoc.text('Uni. Med.: E48 - UNIDAD DE SERVICIO', this.X, this.Y + 5); //UNIDAD DE MEDIDA
            this.X = 112
            this.pdfDoc.text('1.000', this.X, this.Y, { align: 'right' }); // CANTIDAD
            this.X = 135
            this.pdfDoc.text('2645.00', this.X, this.Y, { align: 'right' }); // PRECIO UNITARIO
            this.X = 160
            this.pdfDoc.text('0.00', this.X, this.Y, { align: 'right' }); // PRECIO UNITARIO
            this.X = 187
            this.pdfDoc.text('2645.00', this.X, this.Y, { align: 'right' }); // IMPORTE
            this.pdfDoc.setDrawColor.apply(null, this.separador);
            this.pdfDoc.line(6, this.Y + 8, 200, this.Y + 8) //LINEA SEPARADORA
            this.Y += 14;
          }
        } else {
          this.fcv.fcvsDets.forEach((fd: FcvDet, i) => {

            this.pdfDoc.setFontSize(9); this.tamLinea = 4;

            if ((this.Y + 50) > 280) {
              this.pdfDoc.addPage();
              this.Y = 20;
            }
            if (i > 0) {
              this.pdfDoc.setDrawColor.apply(null, this.separador);
              this.pdfDoc.line(6, this.Y - 5, 200, this.Y - 5) //LINEA SEPARADORA
            }

            this.X = 8
            this.pdfDoc.setFontSize(8);
            if (this.fcv.idMdl !== 70.1) this.pdfDoc.text(`Código SAT: ${fd.art.proSrv}`, this.X, this.Y); // CODIGO SAT
            this.pdfDoc.text(this.g.showOnly(fd.art.art, 63), this.X, this.Y + 5); //CODIGO - PRODUCTO
            if (fd.descripcion) {// SI TIENE DESCRIPCION LA PONE ABAJO
              this.pdfDoc.setFontSize(8);
              this.pdfDoc.text(this.insertaEnter(fd.descripcion, 70), this.X, this.Y + 9.5);
              this.pdfDoc.setFontSize(9);
            }
            this.X = 70
            this.pdfDoc.text(`Uni.Med.: ${fd.art.uniMed.codigo}`, this.X, this.Y); //UNIDAD DE MEDIDA
            this.X = 112
            this.pdfDoc.text(this.numerosFormato.transform(fd.cantidad, 2), this.X, this.Y, { align: 'right' }); // CANTIDAD
            this.X = 135
            this.pdfDoc.text(this.numerosFormato.transform(fd.precioManual, 2), this.X, this.Y, { align: 'right' }); // PRECIO UNITARIO
            this.X = 160
            this.pdfDoc.text(fd.descuentoP ? this.numerosFormato.transform(fd.descuentoP, 2) : '- ', this.X, this.Y, { align: 'right' }); // DESCUENTO
            this.X = 187
            this.pdfDoc.text(this.numerosFormato.transform(fd.descuentoP ? (fd.importe - fd.descuentoI) : fd.importe, 2), this.X, this.Y, { align: 'right' }); // IMPORTE

            // IMPUESTOS DEL CONCEPTO ----------------------------------------------------------------------.
            let huboImpuestos = false;
            if (fd.impuestos.length > 0 && this.fcv.idMdl !== 70.1) {
              huboImpuestos = true
              this.pdfDoc.setFontSize(7); this.tamLinea = 4;
              this.Y += 4
              this.pdfDoc.setFont('Helvetica', 'bold');
              //titulos de impuestos en concepto
              this.X = 113
              this.pdfDoc.text('Impuesto', this.X, this.Y, { align: 'center' });
              this.X = 135
              this.pdfDoc.text('Base', this.X, this.Y, { align: 'center' });
              this.X = 148
              this.pdfDoc.text('Tipo F', this.X, this.Y, { align: 'center' });
              this.X = 163
              this.pdfDoc.text('Tasa', this.X, this.Y, { align: 'center' });
              this.X = 180
              this.pdfDoc.text('importe', this.X, this.Y, { align: 'center' });


              this.pdfDoc.setFont('Helvetica', '');
              fd.impuestos.forEach((imp: Impuesto) => {
                this.Y += 3
                this.X = 105
                this.pdfDoc.text(ImpuestoCode2Text(imp.tipo) + ' ' + imp.imp.imp, this.X, this.Y, { align: 'left' }); //Impuesto
                this.X = 140
                this.pdfDoc.text(this.numerosFormato.transform(fd.baseImp, 2), this.X, this.Y, { align: 'right' }); // Base
                this.X = 148
                this.pdfDoc.text('Tasa', this.X, this.Y, { align: 'center' }); //Tipo
                this.X = 168
                this.pdfDoc.text(this.numerosFormato.transform(imp.tasa, 2) + '%', this.X, this.Y, { align: 'right' }); // Tasa
                this.X = 185
                this.pdfDoc.text(this.numerosFormato.transform(imp.total, 2), this.X, this.Y, { align: 'right' }); // importe
              })
            }
            // ---------------------------------------------------------------------------------------------.
            // doc.setDrawColor.apply(null, separador);
            // doc.line(6, this.Y + (huboImpuestos ? 3 : 8), 200, this.Y + (huboImpuestos ? 3 : 8)) //LINEA SEPARADORA
            if (fd.descripcion && this.enters > 1) {
              let aumento = Number((this.enters * 3.2)) + 4
              // doc.line(6, this.Y + aumento, 200, this.Y + aumento) //LINEA SEPARADORA
              this.Y += aumento + 3;
            } else {
              // doc.line(6, this.Y + (huboImpuestos ? 3 : 8), 200, this.Y + (huboImpuestos ? 3 : 8)) //LINEA SEPARADORA
              this.Y += (huboImpuestos ? 8 : 13);
            }
          })
        }
        // ------------------------------------------------------------------------------.
        // ------------------------------------------------------------------------------.

        // TOTALES ----------------------------------------------------------------------.
        this.pdfDoc.setFontSize(9); this.tamLinea = 4;
        this.pdfDoc.setTextColor.apply(null, this.gris);
        this.pdfDoc.setFont('Helvetica', '');

        this.Y += 4
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Sub-Total', 145, this.Y, { align: 'right' });
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`$ ${this.numerosFormato.transform(this.fcv.subTotal, 2)}`, 187, this.Y, { align: 'right' });
        if (this.fcv.descuentoTotalP) {
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Descuento', 145, this.sigLinea(), { align: 'right' });
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${this.numerosFormato.transform(this.fcv.descuentoTotalP, 2)}%`, 187, this.Y, { align: 'right' });
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Base Total', 145, this.sigLinea(), { align: 'right' });
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`$${this.numerosFormato.transform(this.fcv.baseTotal, 2)}`, 187, this.Y, { align: 'right' });
        }

        this.fcv.imps.forEach(imp => { //IMPUESTOS
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text(`${ImpuestoCode2Text(imp.tipo.replace('IMP', ''))} ${imp.imp.imp} (${imp.tasa}%)`, 145, this.sigLinea(), { align: 'right' });
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`$ ${this.numerosFormato.transform(imp.total, 2)}`, 187, this.Y, { align: 'right' });
        })

        this.pdfDoc.setFontSize(11); this.tamLinea = 6;
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('TOTAL', 145, this.sigLinea(), { align: 'right' });
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text(`$ ${this.numerosFormato.transform(this.fcv.total, 2)}`, 187, this.Y, { align: 'right' });
        // ------------------------------------------------------------------------------.

        if (this.fcv.cartaPorte?.Ubicaciones?.length) this.cartaPorte()

        // TOTAL LETRA + MONEDA ---------------------------------------------------------.
        this.pdfDoc.setFontSize(9); this.tamLinea = 4;
        this.pdfDoc.setTextColor.apply(null, this.gris);
        this.pdfDoc.setFont('Helvetica', '');

        this.X = 8;
        this.Y = 280;
        if ((this.fcv.uuid && this.fcv.uuid.length > 3) || demo) this.Y = 245;
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('TOTAL LETRA: ', this.X, this.Y);
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(numeroALetras(this.fcv.total, getMonedaByMonId(this.fcv.idMon), true), this.X + 25, this.Y);
        this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('MONEDA: ', this.X, this.sigLinea());
        this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`${this.fcv ? this.fcv.idMon : 'MXN'} [Cot. ${this.fcv ? this.numerosFormato.transform(this.fcv.monCot, 2) : '1.00'}]`, this.X + 25, this.Y);
        // ------------------------------------------------------------------------------.
        // CADEDNAS ---------------------------------------------------------------------.
        if ((this.fcv.uuid && this.fcv.uuid.length > 3) || demo) {
          this.X = 42;
          this.Y = 255;
          this.tamLinea = 3;
          this.pdfDoc.setFontSize(9);
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Sello Digital del CFDI', this.X, this.Y);
          this.pdfDoc.setFontSize(5);
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(this.insertaEnter(this.fcv.selloCfdi ? this.fcv.selloCfdi : this.selloDigital, 150), this.X, this.sigLinea());
          this.pdfDoc.setFontSize(9);
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Sello Digital del SAT', this.X, this.sigLinea(this.enters));
          this.pdfDoc.setFontSize(5);
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(this.insertaEnter(this.fcv.selloSat ? String(this.fcv.selloSat) : this.selloDigitalSAT, 150), this.X, this.sigLinea());
          this.pdfDoc.setFontSize(9);
          this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('Cadena Orginal del complento de certificacion digital del SAT.', this.X, this.sigLinea(this.enters));
          this.pdfDoc.setFontSize(5);
          this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(this.insertaEnter(this.fcv.cadOri ? this.g.showOnly(this.fcv.cadOri, 599) : this.cadenaOriginal, 150), this.X, this.sigLinea());
        }
        // -----------------------------------------------------------------------------.

        // CODIGO DE BARRAS BIDIMENCIONAL (CBB) ----------------------------------------.
        this.X = 7;
        this.Y = 253;
        if ((this.fcv.uuid && this.fcv.uuid.length > 3) || demo) this.pdfDoc.addImage(codigoQR, '', this.X, this.Y, 30, 30);
        // ------------------------------------------------------------------------------.

        // CANTIDAD DE PAGINAS Y LEYENDA DE FINAL DE PAGINA------------------------------.
        let cantidadPaginas = this.pdfDoc.internal.getNumberOfPages();
        if (cantidadPaginas > 0) {
          for (let i = 1; i <= cantidadPaginas; i++) {
            this.pdfDoc.setPage(i)
            this.pdfDoc.setFontSize(8);
            this.X = 180;
            this.Y = 292;
            this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.text(`Página ${i} de ${cantidadPaginas}`, this.X, this.Y);
            this.X = 70;
            this.pdfDoc.setFontSize(8);
            if (this.fcv.uuid && this.fcv.uuid.length > 3) {
              this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('ESTA ES UNA REPRESETANCIÓN DE UN CFDI', this.X, this.Y);
            } else {
              this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.text('ESTE DOCUMENTO NO TIENE VALIDEZ FISCAL', this.X, this.Y);
            }
          }
        }
        // -----------------------------------------------------------------------------.
        let dato = ''

        switch (salida) {
          case 'GUARDAR':
            this.pdfDoc.save('Factura-' + new Date().getDate() + '.pdf')
            ok(false);
            break;
          case 'VISTA':
            dato = this.pdfDoc.output('bloburi');
            ok(dato);
            break;
          case 'BLOB':
            dato = this.pdfDoc.output('blob');
            ok(dato);
            break;
          default:
            dato = 'Salida no valida';
            break;
        }
        console.log('ok ok ok')
      }).catch(err => {
        console.log('Aca ta el error: ', err);
        er(err);
      })
    })

  }

  cartaPorte() {

    this.pdfDoc.addPage();
    this.Y = 20;

    this.pdfDoc.setFontSize(11); this.tamLinea = 6;
    this.X = 8
    this.pdfDoc.text('Complemento Carta Porte', this.X, this.sigLinea());
    this.fcv.cartaPorte = new CartaPorteV2(this.fcv.cartaPorte)
    const origen = this.fcv.cartaPorte.getOrigen()
    const destinos = this.fcv.cartaPorte.getDestinos()

    // Generales
    const yPosGenerales = this.Y
    this.pdfDoc.setFont('Helvetica', 'bold'); this.pdfDoc.setFontSize(9); this.tamLinea = 5;
    this.pdfDoc.text('Generales', this.X, this.sigLinea());
    this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.setFontSize(8); this.tamLinea = 4;
    this.pdfDoc.text(`Transporte Internacional: ${this.fcv.cartaPorte.TranspInternac}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Distancia Total Recorrida: ${this.fcv.cartaPorte.TotalDistRec}`, this.X, this.sigLinea());
    // this.pdfDoc.text(`Clave Transporte: ${this.fcv.cartaPorte.FiguraTransporte.CveTransporte}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Número Total de Mercancias: ${this.fcv.cartaPorte.Mercancias.NumTotalMercancias}`, this.X, this.sigLinea());
    this.tamLinea = 5;

    // AUTOTRANSPORTE FEDERAL
    this.Y = yPosGenerales
    this.X = 80
    this.pdfDoc.setFont('Helvetica', 'bold');
    this.pdfDoc.text('AutoTransporte Federal', this.X, this.sigLinea());
    this.pdfDoc.setFont('Helvetica', '');

    // AUTOTRANSPORTE FEDERAL
    const autoTransporte = this.fcv.cartaPorte.Mercancias.Autotransporte
    this.pdfDoc.text(`Permiso SCT: ${autoTransporte.PermSCT}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Nro Permiso SCT: ${autoTransporte.NumPermisoSCT}`, this.X, this.sigLinea());
    const seguros = autoTransporte.Seguros
    this.pdfDoc.text(`Aseguradora de Carga: ${seguros.AseguraCarga} # ${seguros.PolizaCarga}`, this.X, this.sigLinea());
    if (seguros.AseguraMedAmbiente) this.pdfDoc.text(`Aseguradora M. Amb.: ${seguros.AseguraMedAmbiente} # ${seguros.PolizaMedAmbiente}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Aseguradora Resp. Civil: ${seguros.AseguraRespCivil} # ${seguros.PolizaRespCivil}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Prima de Seguro: ${seguros.PrimaSeguro}`, this.X, this.sigLinea());

    // IDENTIFICACION VEHICULAR
    this.pdfDoc.setFont('Helvetica', 'bold');
    this.sigLinea()
    this.pdfDoc.text('Identificación Vehicular', this.X, this.sigLinea());
    this.pdfDoc.setFont('Helvetica', '');
    this.pdfDoc.text(`Configuración Vehicular: ${autoTransporte.IdentificacionVehicular.ConfigVehicular}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Placa: ${autoTransporte.IdentificacionVehicular.PlacaVM}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Año / Modelo: ${autoTransporte.IdentificacionVehicular.AnioModeloVM}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Peso Bruto Vehicular: ${autoTransporte.IdentificacionVehicular.PesoBrutoVehicular}`, this.X, this.sigLinea());
    // REMOLQUES
    if (autoTransporte.Remolques?.length) {
      this.pdfDoc.setFont('Helvetica', 'bold');
      this.sigLinea()
      this.pdfDoc.text('Remolques', this.X, this.sigLinea());
      this.pdfDoc.setFont('Helvetica', '');
      autoTransporte.Remolques.forEach((remolque: Remolque, i: number) => {
        this.pdfDoc.text(`Código del Tipo del Remolque ${i + 1}: ${remolque.SubTipoRem}`, this.X, this.sigLinea());
        this.pdfDoc.text(`Placa Remolque ${i + 1}: ${remolque.Placa}`, this.X, this.sigLinea());
      })
    }


    // ORIGEN
    this.X = 8
    this.Y = 50

    this.pdfDoc.setFontSize(9); this.tamLinea = 5;
    this.pdfDoc.setFont('Helvetica', 'bold');
    const yPosOrigen = this.Y
    this.pdfDoc.text('Origen', this.X, this.sigLinea());
    this.pdfDoc.setFont('Helvetica', ''); this.pdfDoc.setFontSize(8); this.tamLinea = 4;
    const dOrigen = origen.Domicilio
    this.pdfDoc.text(`Identificador: ${origen.IDUbicacion}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Domicilio: ${dOrigen.Calle} Nro. ${dOrigen.NumeroExterior}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Código Colonia: ${dOrigen.Colonia}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Código Postal: ${dOrigen.CodigoPostal}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Código Localidad: ${dOrigen.Localidad}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Código Municipio: ${dOrigen.Municipio}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Código Estado: ${dOrigen.Estado}`, this.X, this.sigLinea());
    this.pdfDoc.text(`Código Pais: ${dOrigen.Pais}`, this.X, this.sigLinea());

    // DESTINO
    // this.Y = yPosOrigen
    // this.X = 110
    this.sigLinea()
    this.pdfDoc.setFont('Helvetica', 'bold');
    this.pdfDoc.text(`${destinos.length > 1 ? 'Destinos' : 'Destino'}`, this.X, this.sigLinea());
    this.pdfDoc.setFont('Helvetica', '');
    destinos.forEach(destino => {
      this.sigLinea()
      if ((this.Y + 20) > 260) {
        this.pdfDoc.addPage();
        this.Y = 20;
        this.sigLinea()
        this.pdfDoc.setFont('Helvetica', 'bold');
        this.pdfDoc.text(`${destinos.length > 1 ? 'Destinos' : 'Destino'}`, this.X, this.sigLinea());
        this.pdfDoc.setFont('Helvetica', '');
        this.sigLinea()
      }
      const dDestino = destino.Domicilio
      this.pdfDoc.text(`Identificador: ${destino.IDUbicacion}`, this.X, this.sigLinea());
      this.pdfDoc.text(`Distancia Recorrida: ${destino.DistanciaRecorrida}`, this.X, this.sigLinea());
      this.pdfDoc.text(`Domicilio: ${dDestino.Calle} Nro. ${dDestino.NumeroExterior}`, this.X, this.sigLinea());
      this.pdfDoc.text(`Código Colonia: ${dDestino.Colonia}`, this.X, this.sigLinea());
      this.pdfDoc.text(`Código Postal: ${dDestino.CodigoPostal}`, this.X, this.sigLinea());
      this.pdfDoc.text(`Código Localidad: ${dDestino.Localidad}`, this.X, this.sigLinea());
      this.pdfDoc.text(`Código Municipio: ${dDestino.Municipio}`, this.X, this.sigLinea());
      this.pdfDoc.text(`Código Estado: ${dDestino.Estado}`, this.X, this.sigLinea());
      this.pdfDoc.text(`Código Pais: ${dDestino.Pais}`, this.X, this.sigLinea());
    })
    if ((this.Y + 20) > 260) {
      this.pdfDoc.addPage();
      this.Y = 20;
    }
    //MERCANCIAS
    this.X = 8
    this.sigLinea()
    this.pdfDoc.setFont('Helvetica', 'bold');
    this.pdfDoc.text('Mercancias Transportadas', this.X, this.sigLinea());
    this.sigLinea()
    this.pdfDoc.text(`Bien Tranportado(Clave)`, this.X, this.Y);
    this.pdfDoc.text(`Cantidad`, this.X + 50, this.Y);
    this.pdfDoc.text(`Clave Unidad`, this.X + 80, this.Y);
    this.pdfDoc.text(`Peso en Kg`, this.X + 120, this.Y);

    this.pdfDoc.setFont('Helvetica', '');
    const mercancias = this.fcv.cartaPorte.Mercancias.Mercancia
    this.sigLinea()
    mercancias.forEach((m, i) => {
      if (i == 0) {
        this.pdfDoc.setDrawColor.apply(null, this.separador);
        this.pdfDoc.line(6, this.Y, 200, this.Y) //LINEA SEPARADORA
        this.sigLinea()
      }
      if ((this.Y + 20) > 260) {
        this.pdfDoc.addPage();
        this.Y = 20;
        this.sigLinea()
        this.pdfDoc.setFont('Helvetica', 'bold');

        this.pdfDoc.text(`Bien Tranportado(Clave)`, this.X, this.Y);
        this.pdfDoc.text(`Clave Unidad`, this.X + 50, this.Y);
        this.pdfDoc.text(`Cantidad`, this.X + 80, this.Y);
        this.pdfDoc.text(`Peso en Kg`, this.X + 120, this.Y);
        this.pdfDoc.setFont('Helvetica', '');
        this.sigLinea()
        this.pdfDoc.setDrawColor.apply(null, this.separador);
        this.pdfDoc.line(6, this.Y, 200, this.Y) //LINEA SEPARADORA
        this.sigLinea()
      }
      this.pdfDoc.text(m.BienesTransp, this.X, this.Y);
      this.pdfDoc.text(String(m.Cantidad), this.X + 80, this.Y);
      this.pdfDoc.text(m.ClaveUnidad, this.X + 50, this.Y);
      this.pdfDoc.text(String(m.PesoEnKg), this.X + 120, this.Y);
      if (m.isMaterialPeligroso) this.pdfDoc.text(`Material Peligroso: ${m.MaterialPeligroso} Embalaje: ${m.Embalaje}`, this.X + 5, this.sigLinea());
      if (m.CantidadTransporta && m.CantidadTransporta.length) {
        this.pdfDoc.setFont('Helvetica', 'bold');
        this.pdfDoc.text(`Detalle:`, this.X, this.sigLinea());
        this.pdfDoc.setFont('Helvetica', '');
        this.sigLinea()
        m.CantidadTransporta.forEach(d => {
          this.pdfDoc.text(`Origen: ${d.IDOrigen}`, this.X, this.Y);
          this.pdfDoc.text(`Destino: ${d.IDDestino}`, this.X + 30, this.Y);
          this.pdfDoc.text(`Cantidad: ${d.Cantidad}`, this.X + 60, this.Y);
          if (d.Placa) this.pdfDoc.text(`Placa: ${d.Placa}`, this.X + 90, this.Y);
          this.sigLinea()
        })

      }
      this.pdfDoc.setDrawColor.apply(null, this.separador);
      this.pdfDoc.line(6, this.Y, 200, this.Y) //LINEA SEPARADORA
      this.sigLinea()
    })




  }

  imagenObjetoHTML(rutaImg: string): HTMLElement {
    let obj = document.createElement("img")
    obj.src = rutaImg;
    return obj;
  }
  sigLinea(lineas?: number): number {
    lineas = lineas ? lineas : 1;
    this.Y += (this.tamLinea * lineas)
    return this.Y;
  }
  insertaEnter(texto: string, cadaCuantosCaracteres: number): string {

    if (texto.length < cadaCuantosCaracteres) return texto



    this.enters = (texto.match(/\n/g) || []).length + 1; //CUENTA LOS ENTERS QUE YA TENIA..
    let textoConEnters = [];

    let posicion = 0
    let i = 0
    for (; i < texto.length; i++) {
      let caracter = texto[i]
      textoConEnters.push(caracter)
      if (caracter === '\n') posicion = 0;
      if (posicion === cadaCuantosCaracteres) {
        textoConEnters.push('\n');
        this.enters++;
        posicion = 0
      }
      posicion++
    }
    return textoConEnters.join('');

  }
  insertaEnter2(texto: string, cadaCuantosCaracteres: number): string {

    if (texto.length < cadaCuantosCaracteres) return texto



    this.enters = (texto.match(/\n/g) || []).length + 1; //CUENTA LOS ENTERS QUE YA TENIA..
    let textoConEnters = '';
    let textoArray = texto.split('\n')

    for (let j = 0; j < textoArray.length; j++) {
      let newTexto = textoArray[j]
      if (textoArray.length > 1) newTexto += '∩{|╪'
      for (let i = 0; i < newTexto.length; i++) {
        if (i + cadaCuantosCaracteres > newTexto.length) {
          textoConEnters += newTexto.substr(i, newTexto.length);
          i = newTexto.length;
        } else {
          textoConEnters += newTexto.substr(i, cadaCuantosCaracteres) + '\n';
          i += cadaCuantosCaracteres - 1;
          this.enters++;
        }
      }
    }
    return textoConEnters;

  }
  getFormaDePago(): string {
    try {

      if (this.fcv?.fiscal?.formaPago?._id) return `${this.fcv.fiscal.formaPago._id} - ${this.fcv.fiscal.formaPago.fondo}`

      return `${this.fcv.fon.fondoTipo.codigo} - ${this.fcv.fon.fondoTipo.fon}`

    } catch (error) {
      return 'Error al obtener forma de pago'
    }
  }

  getUsoCfdi(): string {
    try {

      if (this.fcv?.fiscal?.usoCfdi) return `${this.fcv.fiscal.usoCfdi.codigo} - ${this.fcv.fiscal.usoCfdi.usoCfdi}`

      return `${this.fcv.usoCfdi.codigo} - ${this.fcv.usoCfdi.usoCfdi}`

    } catch (error) {
      return 'Error al obtener uso de cfdi'
    }
  }

  getMetodoDePago(): string {
    try {

      if (this.fcv?.fiscal?.metodoPago?.codigo) return `${this.fcv.fiscal.metodoPago.codigo} - ${this.fcv.fiscal.metodoPago.metPag}`

      return `${this.fcv.metPag.codigo} - ${this.fcv.metPag.metPag}`

    } catch (error) {
      return 'Error al obtener Metodo de Pago'
    }
  }
}

