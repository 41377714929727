export const ImpuestoCode2Text = (code: string) => {

  switch (code) {
    case 'RET': return 'Retención';
    case 'IMP': return 'Traslado';
    case 'RET_LOC': return 'Ret. local';
    case 'IMP_LOC': return 'Imp. local';
    default: return code;
  }

}
