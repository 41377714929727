import { Fcp } from './fcps.model';
import { Art, Impuesto } from './arts.model';
import { Emp } from './emps.model';
import { UsoCfdi } from './interfaces';
import moment from 'moment';import { Sector } from './sectores.model';
import Swal from 'sweetalert2';
import { Fondo } from './fondo.model';
import { CartaPorteInterface } from './cartaPorteData.model';
import { CartaPorteV2 } from './cartaPorteDataV2.model';
import { MotivoCancelacion } from './cfdi.types';

export type monType = 'MXN' | 'USD';
export type Plataforma = '' | 'Shopify' | 'Mercado Libre' | 'WooCommerce' | 'Amazon' | 'Magento' | 'Prestashop';

export class FiscalCfdi {
  exportacion: string = '01';
  formaPago: Fondo = null;
  metodoPago: MetodoPago = { metPag: 'Pago en una sola exhibición', codigo: 'PUE' };
  usoCfdi: UsoCfdi = null;
  comprobanteRelacionado: string = '';
}
export interface MetodoPago { metPag: string, codigo: string };
export interface DatosEcommerce {
  tipo?: 'transferencia' | 'paypal' | 'mercadopago' | '';
  datos?: any
}

export class Fcv {

  public _id: string = '';
  public fcp: Fcp = new Fcp();
  public fcvsDets: FcvDet[] = [];
  public imps: Impuesto[] = [];
  public idUsu: string = '';
  public idSuc: string = '1';
  public idMdl: number = 70;
  public idCot: string = '';
  public idPed: string = '';
  public idNcr: string = '';
  public idFac: string = '';
  public idTik: string = '';
  public codigoCategoria: string = '';

  public facSaldo: number = 0;
  public facRelUUID: string = '';
  public motivoCancelacion: string = null;

  // EXTRAS ---------------------------------

  public ordenCompra: string = '';
  public numeroProveedor: string = '';
  public sector: Sector = Sector.predeterminado();


  // ----------------------------------------

  // FISCAL ---------------------------------
  public fiscal: Partial<FiscalCfdi> = new FiscalCfdi()
  public fecCer: moment.Moment = moment(0);
  public usoCfdi: UsoCfdi;
  public nroSerSat: string;
  public nroSerCsdEmi: string;
  public selloCfdi: string;
  public selloSat: number;
  public cadOri: string;
  public uuid: string = '';
  public rfcPAC: string = '';
  public estadoSAT: string = 'Sin Timbrar';
  public cartaPorte: CartaPorteV2 = null;
  public xml: string = '';
  public xmlAcuseCancelacion: string = '';
  public metPag = { metPag: 'Pago en una sola exhibición', codigo: 'PUE' };
  public fon: Fondo = Fondo.predeterminado();
  // ----------------------------------------
  public plataforma: Plataforma = ''
  public plataformaId: number = 0
  public ecommerce: DatosEcommerce = null
  public idEst: 10 | 11 | 12 = 10;
  public est: string = 'Abierto';
  public cuentaCorriente: boolean = false;
  public punto: string = '';
  public letra: string = '';
  public numero: number = 0;
  public fecEmi: moment.Moment | Date = moment();
  public fecVto: moment.Moment | Date = moment();
  public fecEnt: moment.Moment | Date = moment();
  public idMon: monType = 'MXN';
  public monCot: number = 1;
  public descuentoTipo: '%' | '$' = '%';
  public descuentoP: number = 0; // global ingresado por el usuario
  public descuentoI: number = 0; // global ingresado por el usuario
  public descuentoTotalP: number = 0; // porcentaje de suma de descuentos global + cada uno de los conceptos
  public descuentoTotalI: number = 0; // importe dado por suma de descuentos global + cada uno de los conceptos
  public baseTotal: number = 0; // base para calculo de impuestos
  public subTotal: number = 0;
  public total: number = 0;
  public impTotal: number = 0; // Impuestos trasladados
  public retTotal: number = 0; // Impuestos retenidos
  public impuestoLocalTotal: number = 0; // Impuestos trasladados locales
  public retencionLocalTotal: number = 0; // Impuestos retenidos locales
  public saldo: number = 0;
  public pagoParcialidad: number = 0;
  public obs: string = '';
  public bajaFec: number = 0;
  public baja: number = 0;
  public emp?: Emp
  constructor(obj: any = {}) {
    this._id = obj._id || '';
    this.fcp = obj.fcp || new Fcp();
    this.fcvsDets = obj.fcvsDets || [];
    this.imps = obj.imps || [];
    this.idCot = obj.idCot || '';
    this.idPed = obj.idPed || '';
    this.idNcr = obj.idNcr || '';
    this.idFac = obj.idFac || '';
    this.idTik = obj.idTik || '';
    this.codigoCategoria = obj.codigoCategoria || this.codigoCategoria;
    this.facSaldo = obj.facSaldo || this.facSaldo;
    this.facRelUUID = obj.facRelUUID || this.facRelUUID;
    this.motivoCancelacion = obj.motivoCancelacion || this.motivoCancelacion;
    this.fon = obj.fon || this.fon;
    this.fecCer = moment(obj.fecEmi) || moment(0);

    this.usoCfdi = obj.usoCfdi || { codigo: 'P01', usoCfdi: 'Por definir' };

    // EXTRAS -----------------------------------

    this.ordenCompra = obj.ordenCompra || this.ordenCompra;
    this.numeroProveedor = obj.numeroProveedor || this.numeroProveedor;
    this.sector = obj.sector || this.sector;

    // ------------------------------------------
    // FISCAL -----------------------------------
    this.nroSerSat = obj.nroSerSat || '';
    this.nroSerCsdEmi = obj.nroSerCsdEmi || '00000000000000';
    this.selloCfdi = obj.selloCfdi || '';
    this.selloSat = obj.selloSat || '';
    this.cadOri = obj.cadOri || '';
    this.uuid = obj.uuid || '';
    this.rfcPAC = obj.rfcPAC || '';
    this.estadoSAT = obj.estadoSAT || 'Sin Timbrar';
    this.cartaPorte = obj.cartaPorte || null;
    this.xml = obj.xml || '';
    this.xmlAcuseCancelacion = obj.xmlAcuseCancelacion || '';
    // --------------------------------------

    this.ecommerce = obj.ecommerce || this.ecommerce;
    this.plataforma = obj.plataforma || this.plataforma;
    this.plataformaId = obj.plataformaId || this.plataformaId;
    this.idUsu = obj.idUsu || '';
    this.idSuc = obj.idSuc || this.idSuc;
    this.idMdl = obj.idMdl || 70;
    this.idEst = obj.idEst || 10;
    this.est = String(this.idEst).replace('10', 'Abierto').replace('11', 'Cerrado').replace('12', 'ANULADO')
    this.cuentaCorriente = obj.cuentaCorriente || this.cuentaCorriente;
    this.punto = obj.punto || '';
    this.letra = obj.letra || '';
    this.numero = obj.numero || 0;
    this.fecEmi = moment(obj.fecEmi) || moment();
    this.fecVto = moment(obj.fecVto) || moment();
    this.fecEnt = moment(obj.fecEnt) || moment();
    this.metPag = obj.metPag || { metPag: 'Pago en una sola exhibición', codigo: 'PUE' };
    this.fiscal = obj.fiscal || this.fiscal;
    this.idMon = obj.idMon || 'MXN';
    this.monCot = obj.monCot || 1;
    this.descuentoTipo = obj.descuentoTipo || this.descuentoTipo;
    this.descuentoP = obj.descuentoP || this.descuentoP;
    this.descuentoI = obj.descuentoI || this.descuentoI;
    this.descuentoTotalI = obj.descuentoTotalI || this.descuentoTotalI;
    this.baseTotal = obj.baseTotal || 0;
    this.subTotal = obj.subTotal || 0;
    this.total = obj.total || 0;
    this.impTotal = obj.impTotal || 0;
    this.retTotal = obj.retTotal || 0;
    this.impuestoLocalTotal = obj.impuestoLocalTotal || 0;
    this.retencionLocalTotal = obj.retencionLocalTotal || 0;
    this.saldo = obj.saldo || 0;
    this.pagoParcialidad = obj.pagoParcialidad || 0;
    this.obs = obj.obs || '';
    this.bajaFec = obj.bajaFec || 0;
    this.baja = obj.baja || 0;
    this.emp = obj.emp || new Emp();
  }

  calculaTotalNormal(signo: number) {
		// ASIGNA LOS PRECIOS ORIGINALES ------------------------------------------------------------------------------------.
		/* ( cuando cambia el cliente para que no se queden los precios sin o con IVA ) */
		this.fcvsDets.forEach((fd: FcvDet) => {
			fd.precioManual = fd.precioOriginal
			fd.importe = fd.precioManual * fd.cantidad
			fd.baseImp = fd.importe
		})
		// -------------------------------------------------------------------------------------------------------------------.
		// CALCULA DESCUENTOS POR CADA CONCEPTO ------------------------------------------------------------------------------.
		this.fcvsDets.forEach((fd: FcvDet) => {
			fd.descuentoGlobalP = Number(fd.descuentoGlobalP)
			fd.descuentoGlobalI = Number(fd.descuentoGlobalI)
			fd.descuentoP = Number(fd.descuentoP)
			fd.descuentoI = Number(fd.descuentoI)

			//calcula importe
			fd.importe = Number(fd.cantidad) * Number(fd.precioManual) * signo;
			//calcula descuento del concepto
			fd.descuentoI = 0;
			if (fd.descuentoP && fd.importe) {
				fd.descuentoI = Number(fd.importe * Number((Number(fd.descuentoP / 100).toFixed(6))))
			}
			fd.baseImp = fd.importe - fd.descuentoI
			this.subTotal += fd.importe
		})
		// -------------------------------------------------------------------------------------------------------------------.


		//CACLULA DESCUENTOS GLOBALES PARA CADA CONCEPTO ---------------------------------------------------------------------.
		if (this.descuentoI && this.descuentoTipo === '$' && this.subTotal) {
			//calcula la base total ya con los descuentos individuales
			let totalConDescuentos = 0
			this.fcvsDets.forEach((fd: FcvDet) => {
				totalConDescuentos += fd.importe - fd.descuentoI
			})

			// calcula porcentaje
			this.descuentoP = Number(this.descuentoI) * 100 / Number(totalConDescuentos)
		}


		if (this.descuentoP) {
			// prorratea el descuento por todos los conceptos
			this.fcvsDets.forEach((fd: FcvDet) => {
				fd.descuentoGlobalTipo = this.descuentoTipo
				fd.descuentoGlobalI = Number(this.descuentoI)
				fd.descuentoGlobalP = Number(this.descuentoP)
				fd.descuentoI = 0;
				if ((fd.descuentoP && fd.importe) || (fd.descuentoGlobalP && fd.importe)) {
					fd.descuentoI = Number(fd.importe * Number((Number((fd.descuentoP) / 100).toFixed(6))))
					fd.descuentoI += Number((fd.importe - fd.descuentoI) * Number((Number((fd.descuentoGlobalP) / 100).toFixed(6))))
				}
				fd.baseImp = fd.importe - fd.descuentoI
			})
		}
		// -------------------------------------------------------------------------------------------------------------------.

		//CACLULA IMPUESTOS --------------------------------------------------------------------------------------------------.
		this.baseTotal = 0;

		this.fcvsDets.forEach((fd: FcvDet) => {
			if (this.fcp.impuestosExento || this.idMdl === 70.1 || this.idMdl === 80.1) return;

			if (!fd.art.impuestos) fd.art.impuestos = []

			if (fd.art.impuestos.length) {
				fd.impuestos = []
				fd.impuestos = fd.art.impuestos.filter(imp => {
					if (['RET',  'RET_LOC'].includes(imp.tipo) && !this.fcp.retenciones) return false; // es retencion pero el cliente no retiene
					return true;
				})
			}

			fd.impuestos.forEach((impFd: Impuesto) => {

				impFd.total = Number((fd.baseImp * (impFd.tasa / 100)).toFixed(6))
				// this.impTotal += impFd.total;
				if (impFd.tipo === 'IMP') this.impTotal += impFd.total;
				if (impFd.tipo === 'RET') this.retTotal += impFd.total;
				if (impFd.tipo === 'IMP_LOC') this.impuestoLocalTotal += impFd.total;
				if (impFd.tipo === 'RET_LOC') this.retencionLocalTotal += impFd.total;

				//Busca el impuesto si existe, suma al total, si no lo crea
				let encontrado = false;
				this.imps.forEach((impFcv: Impuesto) => {
					if (impFcv.imp.imp === impFd.imp.imp
						&& impFcv.tipo === impFd.tipo
						&& Number(Number(impFcv.tasa).toFixed(2)) === Number(Number(impFd.tasa).toFixed(2))
					) { //codigo, tipo, tasa iguales
						encontrado = true;
						impFcv.total += Number(impFd.total.toFixed(6));
					}
				})
				if (!encontrado) {
					this.imps.push({
						tipo: impFd.tipo,
						imp: impFd.imp,
						tasa: impFd.tasa,
						total: impFd.total ? Number(impFd.total.toFixed(6)) : 0
					})
				}
			})

			this.baseTotal += fd.baseImp
		})
		// -------------------------------------------------------------------------------------------------------------------.

		// DESCUENTO TOTAL ---------------------------------------------------------------------------------------------------.
		this.descuentoTotalI = 0
		this.fcvsDets.forEach((fd: FcvDet) => {
			this.descuentoTotalI += Number(Number(fd.descuentoI));
		})
		this.descuentoTotalI = (Number((this.descuentoTotalI * 100).toFixed(0))/100)

		if (this.subTotal) this.descuentoTotalP = Number((Number(this.descuentoTotalI) * 100 / this.subTotal).toFixed(2))
		// -------------------------------------------------------------------------------------------------------------------.

	}
	calculaTotalIVAIncluido(signo: number) {
		let ivaIncluido = true
		// LE SACA EL IVA A TODOS LOS DETALLES -------------------------------------------------------------------------------.
		this.fcvsDets.forEach((fd: FcvDet) => {
			fd.precioManual = fd.precioOriginal
			if (this.idMdl === 70.1 || this.idMdl === 80.1) return;
			let importeIVA = 0
			fd.impuestos.forEach(i => {
				if (i.tipo === 'IMP' && i.imp.codigo === '002') importeIVA += fd.precioManual - (fd.precioManual / (1 + (i.tasa / 100)))
			})
			fd.precioManual -= importeIVA
			fd.importe = fd.precioManual * fd.cantidad
			fd.baseImp = fd.importe
		})

		// -------------------------------------------------------------------------------------------------------------------.
		// CALCULA DESCUENTOS POR CADA CONCEPTO ------------------------------------------------------------------------------.
		this.fcvsDets.forEach((fd: FcvDet) => {
			fd.descuentoGlobalP = Number(fd.descuentoGlobalP)
			fd.descuentoGlobalI = Number(fd.descuentoGlobalI)
			fd.descuentoP = Number(fd.descuentoP)
			fd.descuentoI = Number(fd.descuentoI)

			//calcula importe
			fd.importe = Number(fd.cantidad) * Number(fd.precioManual) * signo;
			//calcula descuento del concepto
			fd.descuentoI = 0;
			if (fd.descuentoP && fd.importe) {
				fd.descuentoI = Number(fd.importe * Number((Number(fd.descuentoP / 100).toFixed(6))))
			}
			fd.baseImp = fd.importe - fd.descuentoI
			this.subTotal += fd.importe
		})
		// -------------------------------------------------------------------------------------------------------------------.


		//CACLULA DESCUENTOS GLOBALES PARA CADA CONCEPTO ---------------------------------------------------------------------.
		if (this.descuentoI && this.descuentoTipo === '$' && this.subTotal) {
			//calcula la base total ya con los descuentos individuales
			let totalConDescuentos = 0
			this.fcvsDets.forEach((fd: FcvDet) => {
				totalConDescuentos += (fd.precioOriginal * fd.cantidad) - fd.descuentoI
			})

			// calcula porcentaje
			this.descuentoP = Number(this.descuentoI) * 100 / Number(totalConDescuentos)
		}


		if (this.descuentoP) {
			// prorratea el descuento por todos los conceptos
			this.fcvsDets.forEach((fd: FcvDet) => {
				fd.descuentoGlobalTipo = this.descuentoTipo
				fd.descuentoGlobalI = Number(this.descuentoI)
				fd.descuentoGlobalP = Number(this.descuentoP)
				fd.descuentoI = 0;
				if ((fd.descuentoP && fd.importe) || (fd.descuentoGlobalP && fd.importe)) {
					fd.descuentoI = Number(fd.importe * Number((Number((fd.descuentoP) / 100).toFixed(6))))
					fd.descuentoI += Number((fd.importe - fd.descuentoI) * Number((Number((fd.descuentoGlobalP) / 100).toFixed(6))))
				}
				fd.baseImp = fd.importe - fd.descuentoI
			})
		}
		// -------------------------------------------------------------------------------------------------------------------.

		//CACLULA IMPUESTOS --------------------------------------------------------------------------------------------------.
		this.baseTotal = 0;

		this.fcvsDets.forEach((fd: FcvDet) => {
			if (this.fcp.impuestosExento || this.idMdl === 70.1 || this.idMdl === 80.1) return;

			if (!fd.art.impuestos) fd.art.impuestos = []

			if (fd.art.impuestos.length) {
				fd.impuestos = []
				fd.impuestos = fd.art.impuestos.filter(imp => {
					if (['RET',  'RET_LOC'].includes(imp.tipo) && !this.fcp.retenciones) return false; // es retencion pero el cliente no retiene
					return true;
				})
			}
			fd.impuestos.forEach((impFd: Impuesto) => {

				impFd.total = Number((fd.baseImp * (impFd.tasa / 100)).toFixed(6))
				// this.impTotal += impFd.total;
				if (impFd.tipo === 'IMP') this.impTotal += impFd.total;
				if (impFd.tipo === 'RET') this.retTotal += impFd.total;
				if (impFd.tipo === 'IMP_LOC') this.impuestoLocalTotal += impFd.total;
				if (impFd.tipo === 'RET_LOC') this.retencionLocalTotal += impFd.total;

				//Busca el impuesto si existe, suma al total, si no lo crea
				let encontrado = false;
				this.imps.forEach((impFcv: Impuesto) => {
					if (impFcv.imp.imp === impFd.imp.imp
						&& impFcv.tipo === impFd.tipo
						&& Number(Number(impFcv.tasa).toFixed(2)) === Number(Number(impFd.tasa).toFixed(2))
					) { //codigo, tipo, tasa iguales
						encontrado = true;
						impFcv.total += Number(impFd.total.toFixed(6));
					}
				})
				if (!encontrado) {
					this.imps.push({
						tipo: impFd.tipo,
						imp: impFd.imp,
						tasa: impFd.tasa,
						total: impFd.total ? Number(impFd.total.toFixed(6)) : 0
					})
				}
			})
			this.baseTotal += fd.baseImp
		})
		// -------------------------------------------------------------------------------------------------------------------.

		// DESCUENTO TOTAL ---------------------------------------------------------------------------------------------------.
		this.descuentoTotalI = 0
		this.fcvsDets.forEach((fd: FcvDet) => {
			this.descuentoTotalI += Number(Number(fd.descuentoI));
		})
		this.descuentoTotalI = (Number((this.descuentoTotalI * 100).toFixed(0))/100)

		if (this.subTotal) this.descuentoTotalP = Number((Number(this.descuentoTotalI) * 100 / this.subTotal).toFixed(2))
		// -------------------------------------------------------------------------------------------------------------------.

		// ASIGNA SUBTOTAL ---------------------------------------------------------------------------------------------------.
		if (ivaIncluido && !this.fcp.impuestosExento && !this.descuentoTotalI && this.baseTotal) {
			this.subTotal = this.baseTotal;
		}
		// -------------------------------------------------------------------------------------------------------------------.
	}

	calculaTotal() {
		let signo = 1;
		if (this.idMdl === 71 || this.idMdl === 81) signo = -1

		let ivaIncluido = false;
		if (this.fcp.ivaIncluido) ivaIncluido = true

		this.imps = [];
		this.total = 0;
		// this.saldo = 0;
		this.baseTotal = 0;
		this.subTotal = 0;
		this.impTotal = 0;
		this.retTotal = 0;
		this.impuestoLocalTotal = 0;
		this.retencionLocalTotal = 0;
		this.descuentoP = Number(this.descuentoP)
		this.descuentoI = Number(this.descuentoI)
		this.descuentoTotalP = Number(this.descuentoTotalP)
		this.descuentoTotalI = Number(this.descuentoTotalI)


		if (this.fcp.ivaIncluido) {
			this.calculaTotalIVAIncluido(signo)
		} else {
			this.calculaTotalNormal(signo)
		}


    if (this.subTotal && ((this.descuentoP && this.descuentoP >= 100) || (this.subTotal && this.descuentoTotalI >= (this.subTotal * signo)))) {
      Swal.fire('Descuento', 'El descuento debe de ser menor al importe de la factura.')
      this.descuentoI = 0;
      this.descuentoP = 0;
      this.descuentoTotalP = 0;
      this.descuentoTotalI = 0;
      console.log('Error descuento - El descuento debe de ser menor al importe de la factura.')
      return;
    }

    // FORMATO -----------------------------------------------------------------------------------------------------------.

    this.fcvsDets.forEach((det: FcvDet) => {
      det.precio = Number(Number(det.precio).toFixed(6));
      det.precioManual = Number(Number(det.precioManual).toFixed(6));
      det.precioOriginal = Number(Number(det.precioOriginal).toFixed(6));
      det.baseImp = Number(Number(det.baseImp).toFixed(6));
      det.importe = Number(Number(det.importe).toFixed(6));
      det.cantidad = Number(Number(det.cantidad).toFixed(6));
    })

    this.imps.forEach((imp: any) => {
      imp.total = Number(imp.total.toFixed(2));
    })

    this.baseTotal = Number((this.baseTotal).toFixed(2));
    this.subTotal = Number((this.subTotal).toFixed(2));
    this.impTotal = Number((this.impTotal).toFixed(2));
		this.total = Number((this.subTotal - this.descuentoTotalI + this.impTotal + this.impuestoLocalTotal - this.retTotal - this.retencionLocalTotal).toFixed(2));

    this.saldo = this._id ? this.saldo : this.total;

    // -------------------------------------------------------------------------------------------------------------------.
  }
}

export class FcvDet {

  public _id: string = '';
  public idFcv: string = '';
  public idArt: string = '';
  public atributo1: string = null;
  public atributo2: string = null;
  public atributo3: string = null;
  public atributo4: string = null;
  public descripcion: string = '';
  public costo: number = 0;
  public descuentoGlobalTipo: '%' | '$' = '%'; //dato informativo
  public descuentoGlobalP: number = 0; //dato informativo para saber cuanto era el descuento global
  public descuentoGlobalI: number = 0; //dato informativo para saber cuanto era el descuento global
  public descuentoP: number = 0;
  public descuentoI: number = 0;
  public impuestos: Impuesto[] = [];
  public precio: number = 0;
  public precioManual: number = 0;
  public precioOriginal: number = 0;
  public importe: number = 0;
  public baseImp: number = 0;

  constructor(
    public cantidad: number = 1,
    public art?: Art
  ) {
    if (art) {
      this.art = art;
      this.cantidad = cantidad || this.cantidad;
      this.idArt = this.art._id;
      this.precio = this.precio ? this.precio : this.art.precio;
      this.costo = this.costo ? this.costo : this.art.costo;
      this.impuestos = this.art.impuestos.length ? this.art.impuestos : this.impuestos;
      this.precioManual = this.precioManual || this.art.precio;
      this.precioOriginal = this.precioManual;
      this.importe = this.cantidad * this.precioManual;


    } else {
      this.art = new Art();
    }
  }
  static calculaUtilidad(fcvDet: FcvDet, tipo: '#' | '%' = '%') {
    try {
      let costo = Number(fcvDet.costo)
      let precio = Number(fcvDet.precioManual)
      let utilidad = precio - costo
      let utilidadProcentaje = utilidad * 100 / precio
      return Number((tipo === '%' ? utilidadProcentaje : utilidad).toFixed(2));
    } catch (e) {
      console.error('Error en calculo de utilidad', e);
      return 0
    }
  }
  static getTotalWithTaxes(fcvDet: FcvDet) {
    try {
      let totalImpuestos = 0
      fcvDet.impuestos.forEach(i => {
        totalImpuestos += i.total
      })
      return totalImpuestos + fcvDet.baseImp

    } catch (e) {
      console.error('Error en calculo de getTotalWithTaxes', e);
      return 0
    }
  }
}
